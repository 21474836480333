import React from "react";
import { AspectRatio, Card, CardBody, Heading, Image, Stack, Text } from "@chakra-ui/react";

interface Props {
  src: string;
  price: string;
  title: string;
}

const ProductCard: React.FC<Props> = ({ src, price, title }) => {
  // Function to determine if the word "sale" is in the price
  const isSale = price.toLowerCase().includes("מבצע");

  return (
    <Card>
      <CardBody>
        <AspectRatio ratio={1}>
          <Image src={src} rounded="lg" />
        </AspectRatio>
        <Stack mt={5}>
          <Heading size="md">{title}</Heading>
          {/* Apply custom styling to the price */}
          <Text fontSize="lg" fontWeight="bold" color={isSale ? "red" : "black"}>{price}</Text>
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ProductCard;
