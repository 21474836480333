import React from "react";
import { Box, Heading, Flex } from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaPhone } from "react-icons/fa";

const Header = () => {
    return (
        <Box
            bg="rgba(0, 0, 0, 0.5)" // Transparent black background
            w="100%"
            p={4}
            color="pink"
            height="30vh"
            position="relative" // Make the box a reference for absolutely positioned elements
        >
            {/* Icons positioned in the top left */}
            <Flex
                position="absolute"
                top={10}
                left={10}
                alignItems="flex-start"
            >
                {/* Instagram Icon */}
                <a href="https://www.instagram.com/studiofeya83">
                    <Box mr={6}>
                        <FaInstagram
                            color="white"
                            fontSize= "2rem" // Fixed icon size
                        />
                    </Box>
                </a>
                {/* Facebook Icon */}
                <a href="https://www.facebook.com/profile.php?id=100086507393639&mibextid=eHce3h">
                    <FaFacebook
                        color="white"
                        fontSize= "2rem" // Fixed icon size
                    />
                </a>
            </Flex>

            {/* Phone Icon positioned in the top right */}
            <Flex
                position="absolute"
                top={10}
                right={10}
                alignItems="flex-start"
            >
                <Box mr={6} transform="scaleX(-1)">
                    <FaPhone
                        color="white"
                        fontSize= "2rem" // Fixed icon size
                    />
                </Box>
                <Box color="white">054-578-5723</Box>
            </Flex>

            {/* Name positioned under the phone number */}
            <Box
                position="absolute"
                top={12}
                right={10}
                mt={2} // Margin top to separate from phone number
                textAlign="center"
                color="white"
            >
                אלפיה
            </Box>

            {/* Heading in the middle with gradient text */}
            <Heading
                fontSize={["6vw", "4vw", "4vw", "3vw"]} // Responsive font size
                textAlign="center"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)" // Center horizontally and vertically
                zIndex={1} // Ensure the text is on top of the icons
                bgGradient="linear(to-r, pink, white)" // Linear gradient from pink to white
                bgClip="text" // Clip text to background gradient
            >
                ברוכים הבאים לסטודיו של פיה
            </Heading>
        </Box>
    );
};

export default Header;
